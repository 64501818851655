import { all, put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { push } from '@loan_market/react-router-redux-multi';

import LocalStorageProxy from 'lib/localStorageProxy';
import locale from 'config/locale';
import validator from 'lib/validator';
import { logger as coreLogger } from 'lib/coreLogger';

import {
  signUp,
  activate,
  setMobile,
  getUserInfo,
  activateShared,
  sendEmailVerificationToken,
  recoverPassword,
  verifyEmailAddress as verifyUserEmailAddress,
} from 'services/usersApi';
import { getClient } from 'services/clientApi';
import { getAdvisorInfo } from 'services/advisorsApi';
import * as authApi from 'services/auth/authApi';

import {
  monitorAsyncRequest,
  monitorSpinnerRequest,
  checkIfNetworkError,
} from 'lib/sagaHelpers.js';
import { requestAdvisorInfo } from 'sagas/advisorSagas';
import { requestAggregatorByFamilyId } from 'sagas/aggregatorSagas';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import clientActions from 'actions/clientActions';
import expenseCategoryTypeActions from 'actions/expenseCategoryTypeActions';
import adviserActions from 'actions/advisorActions';

import * as _ from 'sagas/userSagas';

import {
  REQUEST_SIGN_UP,
  REQUEST_USER_INFO,
  REQUEST_LOGIN,
  REQUEST_EMAIL_VERIFICATION,
  REQUEST_VERIFY_RECOVERY_TOKEN,
  REQUEST_RESET_PASSWORD_LINK,
  REQUEST_ACTIVATE_FACT_FIND,
  REQUEST_ACTIVATE_ORGANIC_SIGNUP,
} from 'actions/scenarioActionTypes';

import {
  APPLY_BASE_PATH,
  CONTEXT_PAGE_PATH,
  HELP_SHARED_LOGIN_PATH,
  LOGIN_CAPTURE_PATH,
  NEXT_STEPS_PATH,
} from 'shared/constants/paths';
import { BUSINESS_UNIT_LM_CORPORATE } from 'shared/constants/myCRMTypes/advisors';
import {
  BACK_TO_DASHBOARD_LOGIN,
  SIGN_UP_ANIMATIONS,
  SIGN_UP_ANIMATIONS_FORK,
  BACK_TO_DASHBOARD_HANDHOLD,
  BACK_TO_DASHBOARD_NON_HANDHOLD,
  FROM_EMAIL_VERIFICATION_TO_DASHBOARD,
  toPrivacyPolicy,
  toLoginFromPasswordRecovery,
  toRedirectPath,
} from 'lib/pathHelper';

import { EMAIL_ADDRESS_UNAVAILABLE } from 'constants/messages/authError';

import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';
import * as otherSelectors from 'selectors/otherSelectors';

import { scenario, advisor } from 'selectors/scenarioSelectors';
import {
  advisorOrgIds,
  isOwnBrand,
  byobTradingName,
} from 'selectors/advisorOrgSelectors';
import { workingStructure } from 'selectors/structureSelectors';
import { isHandholdOn } from 'selectors/UISelectors';
import { primaryApplicant } from 'selectors/clientSelectors';
import {
  workingApplication,
  getApplicationId,
} from 'selectors/applicationSelectors';

import { featureOn } from 'lib/abTestHelper';
import { NEW_FORK } from 'config/abTest';

import { getGAClientID } from 'lib/trackAnalyticsHelper';
import { getErrorStatus } from 'lib/errorHelper';
import { featureFlags } from 'lib/rollout';
import {
  setLoginCurrentPath,
  onRefreshExpirationToken,
} from 'lib/utils/common';

import {
  getIdTokenClaims,
  buildOktaUser,
  getDeviceToken,
  signOut,
} from 'lib/okta';
import { setUserId } from 'lib/amplitude';
import { getContextPageType } from 'lib/contextHelper';
import { fetchDataCollectionStatusAndEventsSaga } from './dataCollectionSaga';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function* fetchUserData(isNewLogin = false, trackLogin = 'client') {
  const { application, clientId } = yield call(getUserInfo, isNewLogin);
  const { countryCode, applicants, id: appId, partialSections } = application;
  setUserId(clientId);

  yield call(fetchDataCollectionStatusAndEventsSaga, {
    payload: { applicationId: appId, clientId },
  });

  if (partialSections) {
    yield put(UIActions.setPartialProfileSections(partialSections));
  }

  if (countryCode) {
    locale.countryCode = countryCode;
  }
  const primaryClientInfo = applicants.find(
    (applicant) => applicant.clientId === clientId,
  );

  const primaryBrokerContactId = primaryClientInfo.brokerId;

  const currentAdvisor = yield select(advisor);
  if (
    !primaryClientInfo.isBrokerCustomerCare &&
    currentAdvisor.familyId !== primaryBrokerContactId
  ) {
    yield call(requestAdvisorInfo, {
      payload: {
        familyId: primaryBrokerContactId,
        showOffice: true,
        validateMlgCapability: true,
      },
    });
  }
  yield put(adviserActions.requestAdviserFeatures(primaryBrokerContactId));
  yield fork(requestAggregatorByFamilyId, primaryBrokerContactId);

  const updatedAdvisor = yield select(advisor);

  if (updatedAdvisor.businessUnitId === BUSINESS_UNIT_LM_CORPORATE.id) {
    application.applicants = application.applicants.map((a) => ({
      ...a,
      isBrokerCustomerCare: true,
    }));
  }

  const primaryClient = yield call(getClient, clientId);
  const isAdvisorLogin = trackLogin && trackLogin !== 'client';
  let assignedAdvisor = yield select(advisor);

  if (primaryBrokerContactId && primaryClientInfo.isBrokerCustomerCare) {
    assignedAdvisor = yield call(getAdvisorInfo, {
      familyId: primaryBrokerContactId,
      showOffice: true,
      validateMlgCapability: true,
    });
    yield put(adviserActions.setAdvisorInfo(assignedAdvisor));
  }

  const currentAdvisorOrgIds = yield select(advisorOrgIds);
  if (
    currentAdvisorOrgIds &&
    !currentAdvisorOrgIds.includes(assignedAdvisor.advisorOrgId)
  ) {
    yield put(UIActions.setPageError({ status: 505 }));
    yield call(signOut);
    LocalStorageProxy.clearAll();
    return;
  }

  const trackedAdvisor =
    isAdvisorLogin && primaryBrokerContactId !== trackLogin
      ? yield call(getAdvisorInfo, {
          familyId: trackLogin,
          showOffice: true,
          validateMlgCapability: true,
        })
      : assignedAdvisor;
  yield put(
    scenarioActions.trackCurrentUser({
      isClient: !isAdvisorLogin,
      familyId: isAdvisorLogin
        ? trackedAdvisor.familyId
        : primaryClientInfo.contactId,
      clientId: isAdvisorLogin
        ? trackedAdvisor.clientId
        : primaryClientInfo.clientId,
      countryCode: application.countryCode,
      advisor: trackedAdvisor,
      isNewLogin,
      email: isAdvisorLogin ? trackedAdvisor.email : primaryClient.email,
      mobile: isAdvisorLogin ? trackedAdvisor.mobile : primaryClient.mobile,
      firstName: isAdvisorLogin
        ? trackedAdvisor.firstName
        : primaryClient.firstName,
      lastName: isAdvisorLogin
        ? trackedAdvisor.lastName
        : primaryClient.lastName,
      assignedAdvisorId: assignedAdvisor && assignedAdvisor.familyId,
      appId,
    }),
  );

  yield put(loanApplicationActions.setNewWorkingApplication(application));
  yield put(
    clientActions.setNewClient({
      ...primaryClient,
      primaryApplicant: true,
      advisorId: primaryBrokerContactId,
      hasSignedPrivacyPolicy: primaryClientInfo.hasSignedPrivacyPolicy,
      hasCreditCheckRetried: primaryClientInfo.hasCreditCheckRetried,
      hasSeenContext: primaryClientInfo.hasSeenContext,
      dataCollectionState: primaryClientInfo.dataCollectionState,
    }),
  );
  yield put(expenseCategoryTypeActions.fetchExpenseCategoryTypes());
}

export function* fetchUserInfo({ payload = {}, isShared }) {
  const logger = coreLogger('userSagas');
  try {
    logger.info({
      action: 'fetchUserInfo',
      data: { payload, isShared },
    });
    yield call(_.fetchUserData, payload.isNewLogin, payload.trackLogin);
  } catch (error) {
    // The token is expired or the user doesn't have a scenario.
    logger.error({
      action: 'fetchUserInfo',
      errorDescription: 'Failed to get user info',
      errorStack: error,
    });
    yield call(checkIfNetworkError, error, _.fetchUserInfo);
    if (error?.errorCode === 'login_required') {
      throw error;
    }
    LocalStorageProxy.clearAll();
    if (isShared && error.response.status === 404) {
      throw error;
    }
    yield put(push(LOGIN_CAPTURE_PATH));
    throw error;
  }
}

export function* activateClient(oktaAuth) {
  const logger = coreLogger('userSagas');
  try {
    const userData = yield select(scenario);
    const oktaUser = yield getIdTokenClaims(oktaAuth);
    if (Object.keys(userData.mobile).length > 0) {
      yield call(
        setMobile,
        { ...userData, sharedClientId: oktaUser.clientId },
        yield select(byobTradingName),
      );
      yield put(scenarioActions.setMobileValidated(true));
    }
    return oktaUser;
  } catch (error) {
    logger.error({
      action: 'activateClient',
      errorDescription: 'Failed to activate client',
      errorStack: error,
    });
  }
}

export function* activateFactFind({ payload: oktaAuth }) {
  const logger = coreLogger('userSagas');
  logger.info({
    action: 'activateFactFind',
  });
  try {
    yield put(UIActions.startFullPageSpinner());
    const { clientId } = yield call(_.activateClient, oktaAuth);

    yield call(activateShared, clientId, oktaAuth);
    yield call(fetchUserInfo, {
      payload: { isNewLogin: true, trackLogin: 'client' },
      isShared: true,
    });
    const applicationId = yield select(getApplicationId);
    const applyPath = applicationId && {
      path: `${APPLY_BASE_PATH}/${applicationId}`,
    };
    const loginAnimation = (yield select(isHandholdOn))
      ? BACK_TO_DASHBOARD_HANDHOLD
      : BACK_TO_DASHBOARD_NON_HANDHOLD;

    const hasOldInfo = yield select(otherSelectors.hasOldInfo);
    const isDataCollectionEnabled = yield select(
      dataCollectionSelectors.isDataCollectionEnabled,
    );
    const isDataCollectionNotEnable = yield select(
      dataCollectionSelectors.isDataCollectionNotEnable,
    );
    const applicant = yield select(primaryApplicant);

    const contextPageType = getContextPageType(
      isDataCollectionEnabled,
      hasOldInfo,
      applicant.hasSeenContext,
    );

    if (contextPageType && isDataCollectionNotEnable) {
      yield call(_.checkHasSignedPrivacyPolicy, { path: CONTEXT_PAGE_PATH }, 0);
    } else {
      yield call(_.checkHasSignedPrivacyPolicy, applyPath ?? loginAnimation, 1);
    }
    yield put(UIActions.stopFullPageSpinner());
    setLoginCurrentPath();
  } catch (error) {
    logger.error({
      action: 'activateFactFind',
      errorDescription: 'Failed to activate fact find',
      errorStack: error,
    });
  }
}

export function* activateOrganicSignup({ payload: oktaAuth }) {
  const logger = coreLogger('userSagas');
  logger.info({
    action: 'activateOrganicSignup',
  });
  try {
    yield put(UIActions.startFullPageSpinner());
    const userData = yield select(scenario);
    const structure = yield select(workingStructure);
    const { clientId, familyId, mycrmEmailVerified } = yield call(
      _.activateClient,
      oktaAuth,
    );
    LocalStorageProxy.currentClientId = clientId;
    const oktaMetadata = { clientId, familyId, mycrmEmailVerified };
    if (!mycrmEmailVerified) {
      yield call(sendEmailVerificationToken, oktaAuth);
    }

    const params = {
      scenario: userData,
      structure,
      ...oktaMetadata,
    };
    const gaClientID = getGAClientID();
    const isNewFork = featureOn(NEW_FORK);

    if (gaClientID) {
      params.analyticsClientId = gaClientID;
    }

    if (!userData.returningUser) {
      const { appId } = yield call(activate, params, oktaAuth);
      yield put(scenarioActions.convertScenario({ appId, familyId, clientId }));
    }
    yield call(fetchUserInfo, {
      payload: { isNewLogin: false, trackLogin: 'client' },
    });
    yield call(
      _.checkHasSignedPrivacyPolicy,
      { animation: isNewFork ? SIGN_UP_ANIMATIONS_FORK : SIGN_UP_ANIMATIONS },
      2,
    );
    yield put(UIActions.stopFullPageSpinner());
    setLoginCurrentPath();
  } catch (error) {
    logger.error({
      action: 'activateOrganicSignup',
      errorDescription: 'Failed to activate organic signup',
      errorStack: error,
    });
  }
}

export function* userSignUp() {
  const logger = coreLogger('userSagas');
  try {
    const scenarioData = yield select(scenario);
    logger.info({
      action: 'userSignUp',
      data: { scenario: scenarioData },
    });
    const emailUnavailableError = {
      id: 'email',
      text: EMAIL_ADDRESS_UNAVAILABLE,
      blocking: true,
    };
    const {
      data: { available },
    } = yield call(authApi.getEmailAvailability, scenarioData.email);
    if (!available) {
      yield put(scenarioActions.setError(emailUnavailableError));
      return;
    }

    const { consumerUuid, clientId, familyId } = yield call(signUp, {
      ...scenarioData,
      isOwnBrand: yield select(isOwnBrand),
    });
    yield put(scenarioActions.setConsumerUuid(consumerUuid));
    yield put(
      scenarioActions.trackEmailCapture({
        email: scenarioData.email,
        familyId,
        clientId,
      }),
    );

    const { data } = yield call(
      authApi.register,
      buildOktaUser(scenarioData, familyId, clientId),
    );
    yield put(scenarioActions.setUserId(data.id));

    const { data: oktaData } = yield call(
      authApi.loginWithToken,
      data.activationToken,
    );

    yield put(scenarioActions.setOktaData(oktaData));
  } catch (error) {
    logger.error({
      action: 'userSignUp',
      errorDescription: 'Failed to sign user up',
      errorStack: error,
    });
    yield call(checkIfNetworkError, error, _.userSignUp);
    const errorCauses = error.errorCauses || [];
    const status = error.status || getErrorStatus(error);
    const emailUnavailableError =
      errorCauses[0] === 'EMAIL_ADDRESS_UNAVAILABLE';
    if (emailUnavailableError) {
      yield put(scenarioActions.setError(emailUnavailableError));
    } else {
      yield put(UIActions.setPageError({ status, err: error }));
    }
  }
}

export function* checkHasSignedPrivacyPolicy(
  animationSequence,
  sliceIndex = 0,
) {
  const { path, animation } = animationSequence;
  const { hasSignedPrivacyPolicy } = yield select(primaryApplicant);
  const { isPrivacyPolicyOnly } = yield select(workingApplication);
  const currentAdvisor = yield select(advisor);
  const isPrivacyPolicyEnabled = featureFlags.oneTouchPrivacy.isEnabled();

  if (
    isPrivacyPolicyEnabled &&
    !hasSignedPrivacyPolicy &&
    !!currentAdvisor.privacyPolicyConsent
  ) {
    if (path) {
      yield put(UIActions.setNextPath(path));
    }
    if (animation) {
      yield put(
        UIActions.goToPathWithAnimation(
          toPrivacyPolicy(animation.slice(0, sliceIndex)),
        ),
      );
      const nextAnimation = animation.slice(sliceIndex);
      yield put(UIActions.setNextAnimationSequence(nextAnimation));
    }
  } else if (
    isPrivacyPolicyEnabled &&
    hasSignedPrivacyPolicy &&
    isPrivacyPolicyOnly
  ) {
    yield put(push(NEXT_STEPS_PATH));
  } else if (!path) {
    yield put(UIActions.startAnimationSequence(animation));
  } else {
    yield put(UIActions.goToPathWithAnimation(animationSequence));
  }

  onRefreshExpirationToken();
}

export function* requestLogin({ payload: { username, intent, redirect } }) {
  const logger = coreLogger('userSagas');
  try {
    logger.info({
      action: 'requestLogin',
      data: { username, intent, redirect },
    });
    yield put(UIActions.startFullPageSpinner());
    yield call(_.fetchUserInfo, {
      payload: { isNewLogin: true, trackLogin: 'client' },
    });

    const dashboardAnimation = redirect
      ? toRedirectPath(redirect)
      : BACK_TO_DASHBOARD_LOGIN;

    const applicationId = yield select(getApplicationId);

    const applyPath = applicationId && {
      path: `${APPLY_BASE_PATH}/${applicationId}`,
    };

    const hasOldInfo = yield select(otherSelectors.hasOldInfo);
    const isDataCollectionEnabled = yield select(
      dataCollectionSelectors.isDataCollectionEnabled,
    );
    const applicant = yield select(primaryApplicant);

    const contextPageType = getContextPageType(
      isDataCollectionEnabled,
      hasOldInfo,
      applicant.hasSeenContext,
    );

    const isDataCollectionNotEnable = yield select(
      dataCollectionSelectors.isDataCollectionNotEnable,
    );

    if (contextPageType && isDataCollectionNotEnable) {
      yield call(_.checkHasSignedPrivacyPolicy, { path: CONTEXT_PAGE_PATH }, 0);
    } else {
      yield call(
        _.checkHasSignedPrivacyPolicy,
        applyPath ?? dashboardAnimation,
        0,
      );
    }
    yield put(UIActions.stopFullPageSpinner());
    setLoginCurrentPath();
  } catch (error) {
    logger.error({
      action: 'requestLogin',
      errorDescription: 'Failed to login with email',
      errorStack: error && { message: error.message },
    });
    yield call(checkIfNetworkError, error);
    yield put(
      scenarioActions.setError({
        id: 'ClientLogin',
        text: 'Invalid email or password',
        blocking: false,
      }),
    );
  }
}

export function* verifyEmailAddress({ payload: token }) {
  const logger = coreLogger('userSagas');
  try {
    logger.info({
      action: 'verifyEmailAddress',
      data: { token },
    });
    const response = yield call(verifyUserEmailAddress, token);
    if (response && response.succeeded) {
      const applicationId = yield select(getApplicationId);
      const applyPath = applicationId && {
        path: `${APPLY_BASE_PATH}/${applicationId}`,
      };
      yield put(
        UIActions.goToPathWithAnimation(
          applyPath ?? FROM_EMAIL_VERIFICATION_TO_DASHBOARD,
        ),
      );
    } else {
      throw new Error('Failed to verify email address!');
    }
  } catch (error) {
    logger.error({
      action: 'verifyEmailAddress',
      errorDescription: 'Failed to verify email',
      errorStack: error,
    });
    yield call(checkIfNetworkError, error, _.verifyEmailAddress);
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
}

export function* requestVerifyRecoveryToken({ payload: recoveryToken }) {
  const logger = coreLogger('userSagas');
  try {
    logger.info({
      action: 'requestVerifyRecoveryToken',
      data: { recoveryToken },
    });
    if (!recoveryToken) {
      yield put(scenarioActions.setSharedClientId(null));
      return;
    }
    yield put(scenarioActions.setRecoveryToken(recoveryToken));
    const { data } = yield call(
      authApi.verifyRecoveryToken,
      recoveryToken,
      getDeviceToken(),
    );
    const { user } = data._embedded || {};
    const { data: oktaUser } = yield call(authApi.getUserById, user.id);
    const { mycrmEmailVerified } = oktaUser.profile || {};
    if (mycrmEmailVerified) {
      yield put(scenarioActions.setRecoveryTokenStatus(true));
      return;
    }
    yield put(scenarioActions.setOktaData(data));
    yield put(scenarioActions.setLoginEmail(user && user.profile.login));
    yield put(scenarioActions.setRecoveryTokenStatus(true));
  } catch (error) {
    logger.error({
      action: 'requestVerifyRecoveryToken',
      errorDescription: 'Failed to verify recovery token',
      errorStack: error,
    });
    yield call(checkIfNetworkError, error, _.requestVerifyRecoveryToken);
    yield put(scenarioActions.setRecoveryTokenStatus(false));
    console.error('Failed to verify recovery token.', error);
    yield put(push(HELP_SHARED_LOGIN_PATH));
  }
}

export function* showPasswordRecoveryInstructions() {
  yield put(UIActions.goToPathWithAnimation(toLoginFromPasswordRecovery()));
}

export function* requestResetPasswordLink({ payload: emailAddress }) {
  const logger = coreLogger('userSagas');
  try {
    logger.info({
      action: 'requestResetPasswordLink',
      data: { emailAddress },
    });
    const errorMessage = validator.email(emailAddress);
    if (errorMessage) {
      yield put(
        scenarioActions.setError({
          id: 'resetPassword',
          text: errorMessage,
        }),
      );
      return;
    }
    const currAdvisorOrgIds = yield select(advisorOrgIds);
    yield call(recoverPassword, emailAddress, currAdvisorOrgIds);
    yield call(_.showPasswordRecoveryInstructions);
  } catch (error) {
    logger.error({
      action: 'requestResetPasswordLink',
      errorDescription: 'Failed to send reset password link',
      errorStack: error,
    });
    yield call(checkIfNetworkError, error, _.requestResetPasswordLink);
    if (error.status === 400) {
      // NOTE: this means that the email address doesn't exist. We shouldn't inform the users about this as per acceptance criteria.
      yield call(_.showPasswordRecoveryInstructions);
      return;
    }
    yield put(
      scenarioActions.setError({
        id: 'resetPassword',
        text: 'An unexpected error occurred.',
      }),
    );
  }
}

export default function* userSagas() {
  yield all([
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_ACTIVATE_FACT_FIND,
      activateFactFind,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_ACTIVATE_ORGANIC_SIGNUP,
      activateOrganicSignup,
    ),
    monitorSpinnerRequest(takeEvery, REQUEST_LOGIN, requestLogin),
    monitorSpinnerRequest(takeEvery, REQUEST_SIGN_UP, userSignUp),
    monitorAsyncRequest(takeEvery, REQUEST_USER_INFO, fetchUserInfo),
    monitorAsyncRequest(
      takeEvery,
      REQUEST_EMAIL_VERIFICATION,
      verifyEmailAddress,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_VERIFY_RECOVERY_TOKEN,
      requestVerifyRecoveryToken,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_RESET_PASSWORD_LINK,
      requestResetPasswordLink,
    ),
  ]);
}
