import scenarioActions from 'actions/scenarioActions';
import structureActions from 'actions/structureActions';
import {
  extractQueries,
  isGoalSetter,
  isOneTimePrivacyPolicy,
} from 'lib/utils/browserUtils';
import LocalStorageProxy, { properties } from 'lib/localStorageProxy';
import { storeUtm } from 'lib/utmHelper';
import {
  setupBYOBDefaults,
  setupBrandFaviconForMilli,
} from 'lib/brandColorHelpers';
import { TO_CONTACT_DETAILS_FROM_CALCULATOR } from 'lib/pathHelper';
import {
  REQUEST_USER_INFO,
  SET_DISPLAY_NAME,
  SET_LAST_NAME,
  SET_MOBILE,
  SET_EMAIL,
  SET_POSTCODE_MANUAL_ENTRY,
  SET_ALLOCATED_BROKER_FAMILY_ID,
  FETCH_POSTCODE_COUNTRY,
} from 'actions/scenarioActionTypes';
import {
  SET_PATH_WITH_ANIMATION_SEQUENCE,
  SET_PARTIAL_PROFILE_SECTIONS,
  INITIALISE,
  START_FULL_PAGE_SPINNER,
  STOP_FULL_PAGE_SPINNER,
  START_IS_ONE_TIME_PRIVACY_POLICY,
} from 'actions/UIActionTypes';
import {
  REQUEST_ADVISOR_INFO,
  REQUEST_ADVISOR_LOGIN_REDIRECT,
} from 'actions/advisorActionTypes';

import { REQUEST_ADVISOR_ORG_INFO } from 'actions/advisorOrgActionTypes';
import { READ_LOAN_APPLICATION } from 'actions/loanApplicationActionTypes';
import { LOAD_GOAL_LOAN_APPLICATION } from 'actions/goal/goalLoanApplicationActions';
import { REQUEST_AGGREGATOR } from 'actions/aggregatorActionTypes';
import { REQUEST_PRIVACY_POLICY_INFO } from 'actions/privacyPolicyActionTypes';

import {
  BROKER_LOGIN_PATH,
  CONTACT_DETAILS_PATH,
  LOGIN_CAPTURE_PATH,
} from 'shared/constants/paths';

import locale from 'config/locale';
import {
  NZ_SLUG,
  GOAL_SLUG,
  ADVISOR_UUID,
  ADVISOR_ORG_ID,
} from 'shared/config/pathMatcher';

import { redirectUrl } from 'lib/redirectHelper';
import { fetchTokens } from 'lib/okta';
import { isApplyNow } from './envHelper';
import { logoutUtil, validateLoginCurrentPath } from 'lib/utils/common';

function getSessionStorage(name) {
  let storedState;
  try {
    storedState = JSON.parse(sessionStorage.getItem(name));
  } catch (error) {
    if (error.name === 'SyntaxError') {
      sessionStorage.removeItem(name);
    }
    storedState = null;
  }
  return storedState;
}

function resumeScenario(store, storedState, params) {
  if (storedState && storedState.scenario) {
    store.dispatch(scenarioActions.resumeScenario(storedState.scenario));
  }
  if (params && params.loan_purpose) {
    store.dispatch(scenarioActions.setLoanPurpose(params.loan_purpose));
  }
}

function resumeWorkingStructure(store, storedState) {
  if (storedState && storedState.structure && storedState.structure.working) {
    store.dispatch(
      structureActions.resumeWorkingStructure({
        ...storedState.structure.working,
        page: 1,
      }),
    );
  }
}

function updateWorkingStructure(store, params) {
  if (params.product_id) {
    store.dispatch(structureActions.setProductId(params.product_id));
  }
  if (params.lender_id) {
    store.dispatch(structureActions.setLenderId(params.lender_id));
  }
}

function resumeStoredState(store, storedState, params) {
  resumeScenario(store, storedState, params);
  store.dispatch({
    type: SET_ALLOCATED_BROKER_FAMILY_ID,
    payload: locale.isNZ ? window.LM_CONFIG.NZ_ALLOCATED_BROKER_ID : null,
  });
  resumeWorkingStructure(store, storedState);
}

function resumeCurrentSession(store) {
  if (isGoalSetter()) {
    if (!window.location.hash.includes(BROKER_LOGIN_PATH)) {
      store.dispatch({
        type: LOAD_GOAL_LOAN_APPLICATION,
        payload: LocalStorageProxy.loginAsLoanApplicationId,
      });
    }

    return;
  }

  store.dispatch({
    type: REQUEST_USER_INFO,
    payload: { trackLogin: LocalStorageProxy.brokerFamilyId },
  });

  store.dispatch({ type: READ_LOAN_APPLICATION });

  store.dispatch({
    type: SET_PARTIAL_PROFILE_SECTIONS,
    payload:
      LocalStorageProxy.partialProfileSections &&
      JSON.parse(LocalStorageProxy.partialProfileSections),
  });

  initFullPageSpinner(store);
}

function loadCompareData(store, thirdPartyData) {
  if (!thirdPartyData) {
    return;
  }
  if (thirdPartyData.name) {
    store.dispatch({
      type: SET_DISPLAY_NAME,
      payload: thirdPartyData.name,
    });
  }
  if (thirdPartyData.lastName) {
    store.dispatch({
      type: SET_LAST_NAME,
      payload: thirdPartyData.lastName,
    });
  }
  if (thirdPartyData.mobile) {
    store.dispatch({
      type: SET_MOBILE,
      payload: thirdPartyData.mobile,
    });
  }
  if (thirdPartyData.email) {
    store.dispatch({
      type: SET_EMAIL,
      payload: thirdPartyData.email,
    });
  }
  if (thirdPartyData.postcode) {
    store.dispatch({
      type: SET_POSTCODE_MANUAL_ENTRY,
      payload: { postcode: thirdPartyData.postcode },
    });
  }
}

export const getAdvisorOrAdvisorOrg = (path) => {
  const pathInfo = path
    .split('/')
    .filter((e) => e && ![NZ_SLUG, GOAL_SLUG].includes(e)); // TODO add apply
  const advisorUuidPath = pathInfo.find((a) => ADVISOR_UUID.test(a));
  const advisorOrgId = pathInfo.find((a) => ADVISOR_ORG_ID.test(a));
  const advisorOrgSlug = pathInfo.find(
    (a) => !ADVISOR_UUID.test(a) && !ADVISOR_ORG_ID.test(a),
  );

  const params = generateParams();
  const { advisorUuid: advisorUuidParam } = params;
  return {
    advisorUuid: advisorUuidPath || advisorUuidParam,
    advisorOrgId,
    advisorOrgSlug,
  };
};

export const setWidgetRelatedData = (store, params) => {
  const upcomingData = getSessionStorage(`ao-${params.ref}`);
  switch (params.widget) {
    case 'compare':
      updateWorkingStructure(store, params);
      upcomingData && loadCompareData(store, params.ref);
      break;
    case 'calculator':
      upcomingData && resumeStoredState(store, upcomingData, params);
      store.dispatch({
        type: SET_PATH_WITH_ANIMATION_SEQUENCE,
        payload: TO_CONTACT_DETAILS_FROM_CALCULATOR,
      });
      break;
    default:
  }
};

export const prepareSession = async () => {
  const params = generateParams();
  if (params.recovery) {
    LocalStorageProxy.clearAll();
    sessionStorage.clear();
  }
};

const fetchUserCountryCode = (store) => {
  store.dispatch({
    type: FETCH_POSTCODE_COUNTRY,
  });
};

const prepareStoreForGoalSetter = (store, hash, oktaAuth) => {
  const loanApplicationId = hash.find((h) => !isNaN(Number(h))); // eslint-disable-line no-restricted-globals
  LocalStorageProxy.clear(properties.LOGIN_AS_CLIENT_ID);
  LocalStorageProxy.loginAsLoanApplicationId = loanApplicationId;
  LocalStorageProxy.primaryLoanApplicationId = loanApplicationId;
  store.dispatch({
    type: REQUEST_ADVISOR_LOGIN_REDIRECT,
    payload: { loanApplicationId, oktaAuth, isGoalSetter: true },
  });
};

const prepareStoreForBrokerLogin = (store, hash, oktaAuth) => {
  const clientId = hash[hash.length - 2];
  const loanApplicationId = hash[hash.length - 1];
  LocalStorageProxy.loginAsClientId = clientId;
  LocalStorageProxy.loginAsLoanApplicationId = loanApplicationId;
  store.dispatch({
    type: REQUEST_ADVISOR_LOGIN_REDIRECT,
    payload: { loanApplicationId, oktaAuth },
  });
};

const prepareStoreForSharedLogin = (store) => {
  LocalStorageProxy.clearAll();
  initFullPageSpinner(store);
};

const prepareStoreForOneTimePrivacyPolicy = (store) => {
  LocalStorageProxy.clearAll();
  store.dispatch({ type: START_IS_ONE_TIME_PRIVACY_POLICY });
};

const prepareStoreForOkta = async (store, oktaAuth) => {
  const params = generateParams();
  const hash = window.location.hash;
  const hashArr = hash.split('/');
  const isBrokerLogin = hash.includes(BROKER_LOGIN_PATH);
  const isRegistration =
    hash.includes(CONTACT_DETAILS_PATH) || hash.includes(LOGIN_CAPTURE_PATH);
  const isAuthenticated = await oktaAuth.isAuthenticated();
  const sessionToken = LocalStorageProxy.token;
  const sharedURL = window.location.href;

  if (sharedURL.includes('recovery') && sessionToken) {
    logoutUtil(sharedURL, oktaAuth);
  }

  if (isGoalSetter()) {
    prepareStoreForGoalSetter(store, hashArr, oktaAuth);
  } else if (isBrokerLogin) {
    prepareStoreForBrokerLogin(store, hashArr, oktaAuth);
  } else if (params.recovery && !isAuthenticated) {
    prepareStoreForSharedLogin(store);
  } else if (params.recovery && isAuthenticated) {
    resumeCurrentSession(store);
  } else if (!isBrokerLogin && !isRegistration) {
    resumeCurrentSession(store);
  }
};

export const removeChatWidget = () => {
  const chatbotElem = document.querySelector('#chat-widget-container');
  chatbotElem && chatbotElem.remove();
};

export const subscribeOktaAuthState = (oktaAuth, store) => {
  oktaAuth.authStateManager.subscribe(async (authState) => {
    const newToken = authState.accessToken?.accessToken;
    if (authState.isAuthenticated) {
      LocalStorageProxy.token = newToken;
      prepareStoreForOkta(store, oktaAuth);
      removeChatWidget();
      validateLoginCurrentPath();
    } else if (!authState.isAuthenticated && isOneTimePrivacyPolicy()) {
      prepareStoreForOneTimePrivacyPolicy(store);
    } else {
      resumeStoredMilliState(store);

      const { hash } = window.location;
      if (hash.includes(BROKER_LOGIN_PATH)) {
        fetchTokens(oktaAuth, () => initFullPageSpinner(store));
      } else {
        initFullPageSpinner(store);
      }

      const params = generateParams();
      if (window.location.pathname === '/' && !params.recovery) {
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          'https://cht-srvc.net/api/lc.js?client=lma_loan_market_australia',
        );
        // eslint-disable-next-line unicorn/prefer-query-selector, unicorn/prefer-node-append
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }
  });
};

const resumeStoredMilliState = (store) => {
  const params = generateParams();
  const milliData = getSessionStorage('milli');
  resumeStoredState(store, milliData, params);
};

export const fetchAdviserByUuid = (store) => {
  const { advisorUuid } = getAdvisorOrAdvisorOrg(window.location.pathname);
  if (!advisorUuid) {
    return;
  }
  LocalStorageProxy.clearAll();
  sessionStorage.clear();
  store.dispatch({
    type: REQUEST_ADVISOR_INFO,
    payload: {
      uuid: advisorUuid,
      showOffice: true,
      validateMlgCapability: true,
    },
  });
};

const fetchAggregator = (store) => {
  store.dispatch({
    type: REQUEST_AGGREGATOR,
  });
};

const fetchPrivacyPolicyInfo = (store) => {
  const params = generateParams();
  const { token, name, advisorUuid } = params;
  store.dispatch({
    type: REQUEST_PRIVACY_POLICY_INFO,
    payload: { token, name, advisorUuid },
  });
};

const initFullPageSpinner = (store) => {
  const {
    UISettings: { fullPageSpinnerLoading },
  } = store.getState();
  if (fullPageSpinnerLoading === undefined) {
    store.dispatch({ type: START_FULL_PAGE_SPINNER });
  } else {
    store.dispatch({ type: STOP_FULL_PAGE_SPINNER });
  }
};

export const generateParams = () => ({
  ...extractQueries(window.location.hash),
  ...extractQueries(window.location.search),
});

export default async (store) => {
  // For deep linking from Loan Market homepage, it might come in as:
  // loanmarket.com.au/#/reason-for-refinance?utm_source=LoanMarket&loan_purpose=Investment or
  // loanmarket.com.au?gcid=elwersdf&loan_purpose=Investment/#/reason-for-refinance
  // Also for homepage, users open the tiles in a new tab..
  const params = generateParams();
  if (LocalStorageProxy.logoutRedirectUri) {
    window.location.assign(LocalStorageProxy.logoutRedirectUri);
    LocalStorageProxy.clear(properties.LOGOUT_REDIRECT_URI);
  }

  store.dispatch({ type: INITIALISE });
  initFullPageSpinner(store);

  const { advisorUuid, advisorOrgSlug, advisorOrgId } = getAdvisorOrAdvisorOrg(
    window.location.pathname,
  );

  if (advisorOrgSlug && advisorOrgSlug !== 'authorization-code') {
    // if coming from GA with incorrect form,
    // e.g: https://my.loanmarket.com.au/&_ga=2.205754453.753101868.1571713337-2016028058.1563175564#/buyer-scenarios?loan_purpose=Residential
    // reorder the url parts.
    LocalStorageProxy.advisorOrgSlug = advisorOrgSlug;
    const gaRedirectUrl = redirectUrl(window.location);
    if (gaRedirectUrl) {
      window.location.replace(gaRedirectUrl);
    }
    store.dispatch({
      type: REQUEST_ADVISOR_ORG_INFO,
      payload: {
        id: advisorOrgId,
        slug: advisorOrgSlug,
        shouldFetchPrincipleAdvisor: !advisorUuid,
        shouldContinueSpinner:
          window.location.hash.indexOf(BROKER_LOGIN_PATH) > 0,
      },
    });
    removeChatWidget();
  } else if (isApplyNow()) {
    LocalStorageProxy.clear(properties.ADVISOR_ORG_SLUG);
    setupBYOBDefaults();
  } else {
    fetchAggregator(store);
    setupBrandFaviconForMilli();
  }

  if (isOneTimePrivacyPolicy()) {
    fetchPrivacyPolicyInfo(store);
  } else {
    fetchAdviserByUuid(store);
  }

  storeUtm(store, params);

  if (params.widget) {
    setWidgetRelatedData(store, params);
  }

  fetchUserCountryCode(store);
};
